<template>
  <div>
    <h2>Geo Data</h2>
    <p>{{ "Longitude:  " + longitude }}</p>
    <p>{{ "Latitude:  " + latitude }}</p>
    <p v-if="address.length">
    <h3>{{address}}</h3>
    <p><b>City: </b>{{city}}</p>
    <p><b>District: </b>{{district}}</p>
    <p><b>State: </b>{{state}}</p>
    <p><b>Country: </b>{{country}}</p>

    </p>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import VueCookie from "vue-cookie";
Vue.use(VueCookie);

export default {
  data() {
    return {
      longitude: "",
      latitude: "",
      address: "",
      state: "",
      district: "",
      city: "",
      country: "",
      country_code : "",
    };
  },
  async mounted() {
    this.longitude = VueCookie.get("longitude");
    this.latitude = VueCookie.get("latitude");
    if (this.longitude && this.latitude) {
      const { data } = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${this.latitude}&lon=${this.longitude}&zoom=10&format=json`
      );
      if(data && data.address)
     { this.address = data.display_name;
      this.state = data.address.state;
      this.district = data.address.state_district;
      this.city = data.address.city;
      this.country = data.address.country;
      this.country_code = data.address.country_code;
    }
    }
    if (!VueCookie.get("longitude") && VueCookie.get("latitude"))
      try {
        __native_money91.location = (res, locatnPermission) => {
          console.log({ res, locatnPermission });
          if (!res && locatnPermission === "LOCATION_PERMISSION_DENIED") {
            alert("Please Enable Your App Permission");
          } else if (!res && locatnPermission === "LOCATION_NOT_ENABLED") {
            alert("Please Enable your App Location");
          } else if (res && !locatnPermission) {
            // this.$router.push("/");
            window.location.reload();
            console.log("lat , long: " + res);
          }
        };
        JSBridgePlugin.requestLocation(
          "Please allow the app to use location services",
          "Please allow the permission",
          "__native_money91.location"
        );
      } catch (err) {
        console.log(err);
      }

    // if (!VueCookie.get("longitude") && !VueCookie.get("latitude")) {
    //   try {
    //     __native_money91.location = (res, locatnPermission) => {
    //       if (!res && locatnPermission === "LOCATION_PERMISSION_DENIED") {
    //         alert("Please Enable Your App Permission");
    //       } else if (!res && locatnPermission === "LOCATION_NOT_ENABLED") {
    //         alert("Please Enable your App Location.");
    //       }
    //     };
    //     JSBridgePlugin.getLocation(" __native_money91.location");
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }
    //   this.$store.dispatch({ type: "postCheckIn", payload: checkInDoc }).then(
    //     data => {
    //       if (data.status == "Success") {
    //         this.loading = false;
    //         this.$store.commit(types.LOADFEEDS, []);
    //         this.$router.push("/");
    //       }
    //     },
    //     response => {}
    //   );
    // } else {
    //     console.log("enter details")
    // //   this.$toast.bottom(this.$t("enterdetails"));
    // }
  },
};
/* SAMPLE GEOCODE DATA at ZOOM=10
{
        place_id: 174551979,
        licence:
          "Data © OpenStreetMap contributors, ODbL 1.0. https://osm.org/copyright",
        osm_type: "way",
        osm_id: 283113808,
        lat: "29.3917821",
        lon: "79.455569",
        display_name: "Nainital, Uttarakhand, 263003, India",
        address: {
          town: "Nainital",
          county: "Nainital",
          state_district: "Nainital",
          state: "Uttarakhand",
          "ISO3166-2-lvl4": "IN-UT",
          postcode: "263003",
          country: "India",
          country_code: "in",
        },
        boundingbox: ["29.3726322", "29.3987891", "79.4388306", "79.4699871"],
      },
*/
</script>
